import { Component, HostListener, OnInit, ɵConsole } from '@angular/core';
import { AuthService } from 'app/security/auth.service';
import { NotificationService } from 'app/services/notification-service';
import { IsMobileService } from 'app/commons/components/isMobile.service';
import { ToastrService } from 'ngx-toastr';
import { CommonsText } from 'app/commons/CommonsText';

@Component({
  selector: 'app-header-navigation-bar',
  templateUrl: './header-navigation-bar.component.html',
  styleUrls: ['./header-navigation-bar.component.scss']
})
export class HeaderNavigationBarComponent implements OnInit {

  burgerState: Boolean = false;
  showChangeEntity: Boolean = false;
  showDirectAreas: Boolean = true;
  scrolled: Boolean = false;

  showScrollHeight: Number = 115;
  hideScrollHeight: Number = 115;

  toggle: Boolean = true;

  loadingNotifications: Boolean = false;
  notifications: any;

  loadingPolicyNotifications: Boolean = false;
  policyNotifications: any;

  loadingEventNotifications: Boolean = false;
  eventNotifications: any;

  pendingNotifications: boolean;
  pendingEventNotifications: boolean;
  pendingPolicyNotifications: boolean;

  readingNotifications: boolean;

  isMobile: Boolean = false;
  showClubPropietario: Boolean = false;

  toggleBurger() {
      this.burgerState = !this.burgerState;
  }

  @HostListener('window:scroll', []) onScroll() {

      const pageYOffset = window.pageYOffset;
      const docScrollTop = document.documentElement.scrollTop;
      const bodyScrollTop = document.body.scrollTop;
      const bodyScrollBottom = document.body.offsetHeight;
      const clientHeight = document.documentElement.clientHeight;

      if ((pageYOffset || docScrollTop || bodyScrollTop) > this.showScrollHeight) {
          this.scrolled = true;
      } else if (
          (this.scrolled && (pageYOffset || docScrollTop || bodyScrollTop) < this.hideScrollHeight)
          && (bodyScrollBottom > clientHeight + Number(this.showScrollHeight))) {
          this.scrolled = false;
      }
      else if (this.scrolled && docScrollTop == 0) {
          this.scrolled = false;
      }

  }

  getBurgerClass(): String {
      let result = '';
      if (this.burgerState) {
          result += 'open ';
      }
      if (!this.scrolled || (this.scrolled && this.burgerState)) {
          result += ' burger-white';
      } else {
          result += ' burger-blue';
      }
      return result;

  }

  constructor(
      private isMobileService: IsMobileService, 
      private authService: AuthService, 
      private notificationService: NotificationService,
      private toastr: ToastrService) { 
          this.isMobile = isMobileService.isMobile;
      }

  ngOnInit(): void {
      if ((this.authService.isLogged()) && (this.authService.getUser().entities.length > 1)) {
          this.showChangeEntity = true;
      }
      this.authService.getSelectedEntity() === 'ONLYGAL' ? this.showDirectAreas = true : this.showDirectAreas = false;

      // Obtener notificaciones dado la configuración obtenida.

      this.loadingNotifications = true;
      this.notificationService.getNotifications()
          .then(success => {
              this.loadingNotifications = false;
              this.notifications = success.response;
          }).catch(error => {
              this.loadingNotifications = false;
              this.notifications = [];
              this.toastr.error(CommonsText.PROBLEMA_SERVICIO_NOTI_RECI, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                  timeOut: 5000
              });
          });

          if(this.showDirectAreas){
            this.showClubPropietario = this.authService.getProductos();
          }
  }

  checkPendingNotifications() {
      this.pendingNotifications = false;

      if (this.notifications) {
          this.notifications.forEach(not => {
              if (not.isRead != 'S') {
                  this.pendingNotifications = true;
              }
          });
      }

      return this.pendingNotifications;
  }


  delete(notification) {
      let arrayIds: number[] = [notification.agenNotId];
      this.notificationService.markNotificationAsRead(arrayIds)
          .then(success => {
              let index = null;
              const found = this.notifications.find(n => n.agenNotId === notification.agenNotId);

              if (found) {
                  index = this.notifications.indexOf(found);
                  if (index > -1) {
                      this.notifications.splice(index, 1);
                  }

                  if (this.notifications.length == 0) {
                      this.pendingNotifications = false;
                  }
              }
          }).catch(error => {
              this.toastr.error(CommonsText.PROBLEMA_SERVICIO_NOTIFICACIONES, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                  timeOut: 5000
              });
          });

  }


  markAsRead(notification) {
      if (notification && notification.isRead != 'S') {
          const found = this.notifications.find(n => n.agenNotId === notification.agenNotId);
          if (found) {
              const index = this.notifications.indexOf(found);
              found.isRead = 'S';
              this.notifications[index] = found;
              this.checkPendingNotifications();
          }

          let arrayIds: number[] = [notification.agenNotId];
          this.notificationService.markNotificationAsRead(arrayIds)
              .then(success => {

              }).catch(error => {
                  const found = this.notifications.find(n => n.agenNotId === notification.agenNotId);
                  if (found) {
                      const index = this.notifications.indexOf(found);
                      found.isRead = '';
                      this.notifications[index] = found;
                      this.checkPendingNotifications();
                  }
                  this.toastr.error(CommonsText.PROBLEMA_SERVICIO_NOTIFICACIONES, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                      timeOut: 5000
                  });
              });
      }
  }

  readAll() {
      this.readingNotifications = true;
      let arrayIds: number[] = [];
      this.notifications.forEach(n => {
          arrayIds.push(n.agenNotId)
      });
      this.notificationService.markNotificationAsRead(arrayIds)
          .then(success => {
              this.readingNotifications = false;
              this.notifications.forEach(n => {
                  n.isRead = 'S'
              });
              this.pendingNotifications = false;
          }).catch(error => {
              this.readingNotifications = false;
              this.toastr.error(CommonsText.PROBLEMA_SERVICIO_NOTIFICACIONES, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                  timeOut: 5000
              });
          });
  }


  deleteAll() {
      this.readingNotifications = true;
      let arrayIds: number[] = [];
      this.notifications.forEach(n => {
          arrayIds.push(n.agenNotId)
      });
      this.notificationService.markNotificationAsRead(arrayIds)
          .then(success => {
              this.notifications = [];
              this.pendingNotifications = false;
              this.readingNotifications = false;
          }).catch(error => {
              this.readingNotifications = false;
              this.toastr.error(CommonsText.PROBLEMA_SERVICIO_NOTIFICACIONES, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                  timeOut: 5000
              });
          });
  }

  getEntityClassNav(){
      switch (localStorage.getItem('entity')) {
        case 'C0494':
            return 'bar-meridiano';
        case 'L0639':
            return 'bar-euroins';
        case 'L1454':
            return 'bar-prima';
        case 'C0616':
            return 'bar-seguro-rga';
          case 'C0012':
              return 'bar-eterna-aseguradora';
          case 'C0037':
              return 'bar-santander';
          case 'C0072':
              return 'bar-generali';
          case 'C0124':
              return 'bar-segurcaixa';
          case 'C0133':
              return 'bar-ocaso';
          case 'C0139':
              return 'bar-patria-hispana';
          case 'C0157':
              return 'bar-helvetia';
          case 'C0161':
              return 'bar-dkv';
          case 'C0572':
              return 'bar-lagun-aro';
          case 'C0786':
              return 'bar-mutua';
          case 'DLA03':
              return 'bar-aegon';
          case 'DLA04':
              return 'bar-ergo';
          case 'DLA06':
              return 'bar-amic';
          case 'DLA07':
              return 'bar-allianz';
          case 'DLA09':
              return 'bar-vidacaixa';
          case 'E0217':
              return 'bar-erv';
          case 'L0614':
              return 'bar-nationale-suisse';
          case 'L0639':
              return 'bar-euroins';
          case 'M0084':
              return 'bar-mmt';
          case 'M0199':
              return 'bar-mutua-propietarios';
          default:
              return '';
      }
  }

  getEntityClass(){
      switch (localStorage.getItem('entity')) {
        case 'C0494':
            return 'meridiano';
        case 'L0639':
            return 'euroins';
        case 'L1454':
            return 'prima';
        case 'C0616':
            return 'seguro-rga';
          case 'C0012':
              return 'eterna-aseguradora';
          case 'C0037':
              return 'santander';
          case 'C0072':
              return 'generali';
          case 'C0124':
              return 'segurcaixa';
          case 'C0133':
              return 'ocaso';
          case 'C0139':
              return 'patria-hispana';
          case 'C0157':
              return 'helvetia';
          case 'C0161':
              return 'dkv';
          case 'C0572':
              return 'lagun-aro';
          case 'C0786':
              return 'mutua';
          case 'DLA03':
              return 'aegon';
          case 'DLA04':
              return 'ergo';
          case 'DLA06':
              return 'amic';
          case 'DLA07':
              return 'allianz';
          case 'DLA09':
              return 'vidacaixa';
          case 'E0217':
              return 'erv';
          case 'L0614':
              return 'nationale-suisse';
          case 'L0639':
              return 'euroins';
          case 'M0084':
              return 'mmt';
          case 'M0199':
              return 'mutua-propietarios';
          default:
              return '';
      }
  }

}
